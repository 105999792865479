import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Routes from "../routes"
import { useImpression } from "../analytics/utils"

const Atheism = ({ location }) => {
  useImpression("Atheism")
  return (
    <Layout location={location}>
      <Seo
        title="What it means to be atheist"
        description="Over 7% of the world identifies as atheist or agnostic. But what does atheism really mean? Let's find out, because it's growing faster than you think."
      />
      <h1>Atheism</h1>
      <h2>What does atheism mean?</h2>
      <p>
        Atheism is an overloaded term, but ultimately boils down to the lack of
        belief in the existence of god.
      </p>
      <p>
        Atheist will tend to reject every one of the world's religions, granted
        most of us do this all the time. When was the last time you met someone
        who believes in Thor or Horus? All of us, atheists and religious alike,
        are atheist with respect to those religions. Atheists just take it one
        step further by rejecting the final leftover religion, of which most of
        us were born into.
      </p>
      <h3>What happens when you become an atheist?</h3>
      <p>
        Freedom. Honestly, it’s liberating. For a majority of your life you live
        under the impression that you’re being watched. That every choice you
        make could factor into whether or not “god” lets you into his heaven for
        eternity, or if your stuck in hell for eternal torture. What sort of a
        life is that? So when you give up the idea of “god” and religion, you
        can immediately leave the guilt, shame, and fear in the past. Not only
        that, but now whenever you do good things you know they are coming from
        you and only you. You’re not a puppet for the all powerful god, you’re a
        human being. And you make decisions all day everyday. And you choose to
        be good, or bad (but be good)!
      </p>
      <p>
        Generally, atheists reject existing religion's gods based on a lack of
        evidence and support. Atheism is often seen as the opposite of theism.
        However, I don't think many atheists intend to hold the view that it's
        impossible for a god to exist. Rather, they just see the idea of blind
        faith unappealing and identify as atheist.
      </p>
      <p>
        Here's an interesting way to think about atheism that can appeal to you
        even if you're a religious person: A majority of the world identifies
        with a monotheistic religion. Historically, the world was much more
        polytheistic, but over time we have lost interest in polytheism and now
        mostly believe in just one. But monotheists who can agree that
        polytheism is incorrect would have to agree that they are "atheists"
        with respect to religions they don't believe in. So almost everyone
        knows what it feels like to be an atheist. But people we actually label
        "atheist" tend to take it one step further, leaving the monotheistic
        ideas behind as well.
      </p>
      <p>
        For some reason atheism is seen as taboo in today's world. And people
        often hold back on discussing these types of topics. Sort of like how
        you might not bring up politics, you might leave religion out of most
        conversations. Yet, it dominates in popularity across a majority of the
        world. According to the Pew Research Center in 2012, they found:
      </p>
      <table>
        <thead>
          <tr>
            <th>Religion</th>
            <th>Total population</th>
            <th>% of Earth's population</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Christians</td>
            <td>2.2 billion</td>
            <td>32%</td>
          </tr>
          <tr>
            <td>Muslims</td>
            <td>1.6 billion</td>
            <td>23%</td>
          </tr>
          <tr>
            <td>Hindus</td>

            <td>1 billion</td>
            <td>15%</td>
          </tr>
          <tr>
            <td>Buddhists</td>
            <td>500 million</td>
            <td>7%</td>
          </tr>
        </tbody>
      </table>
      <p>
        These are huge numbers. We're talking about a topic that affects so many
        of our friends, family, colleagues, and other humans. These
        conversations matter, they make up our future. So, let's get down to
        business...
      </p>
      <h2>Why should you be an atheist?</h2>
      <p>
        Given the fact that many religions conflict with one another, and most
        lack most or all evidence to support their claims. Aside from 'sacred
        texts', religions offer little to support itself with. At the center of
        many religious debates, you often find the religious person claiming
        that there are specific beliefs that are simply based in faith. But when
        you leave the realm of scientific analysis like that, you lose the
        credibility to make truth claims because there can be no scientific
        investigation. Therefore, it appears that maintaining an atheist outlook
        on life seems more logical than blinding following beliefs that have
        been passed down for centuries, translated, modified, and hand-picked.
      </p>
      <hr />
      <p>
        Be sure to check out our page about what it means to be{" "}
        <Link to={Routes.AGNOSTIC}>Agnostic</Link>.
      </p>
    </Layout>
  )
}

export default Atheism
